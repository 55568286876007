export const QUERY_KEYS = Object.freeze({
  GET_ALL_COURSES: 'getAllCourses',
  GET_ALL_CUSTOMERS: 'getAllCustomers',
  GET_COURSE_DETAILS: 'getCourseDetails',
  GET_ALL_PAYMENTS: 'getAllPayments',
  GET_PAYMENTS_DETAILS: 'getPaymentsDetails',
  GET_REFUND_PAYMENT: 'getRefundPayment',
  GET_REFUND_PAYMENT_DETAILS: 'getRefundPaymentDetails',
  GET_ALL_ORDERS: 'getAllOrders',
  GET_ORDER_DETAILS: 'getOrderDetails',
  GET_CUSTOMER_DETAILS: 'getCustomerDetails',
  GET_CUSTOMER_PAYMENTS: 'getCustomerPayments',
  GET_CUSTOMER_ORDERS: 'getCustomerOrders',
  GET_CUSTOMER_COURSES: 'getCustomerCourses',
  GET_GLOBAL_DATA: 'getGlobalData',
  GET_USER_DETAILS: 'getUserDetails',
  GET_REFERRER_DETAILS: 'getReferrerDetails',
  GET_REFERRAL_ORDER_HISTORY: 'getReferralOrderDetails',
  GET_TEST_DETAILS: 'getTestDetails',
  GET_ALL_TESTS: 'getAllTests',
  GET_ALL_USERS: 'getAllUsers',
  GET_ALL_ROLES: 'getAllRoles',
  GET_ALL_PERMISSIONS: 'getAllPermissions',
  GET_TEST_CATEGORIES: 'getTestCategories',
  GET_ALL_EMP_Referral: 'getAllEmpReferral',
  GET_ALL_PAYOUTS: 'getAllPayouts',
  GET_PAYOUT_DETAILS: 'getPayoutDetails',
  GET_ALL_EMP_REFERRAL: 'getAllEmpReferral',
  GET_EMPLOYER_DETAILS: 'getEmployerDetails',
  GET_ALL_EMPLOYER: 'getAllEmployer',
  GET_ALL_STAFF: 'getAllStaff',
  GET_EARNINGS: 'getEarnings',
  GET_ALL_FIELD_AGENTS: 'getAllFieldAgents',
});
