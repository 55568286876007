import { useState, useEffect, useRef } from 'react';
import { usePostUploadToS3 } from '../apis/queryHooks';
import { bytesToMegabytes } from '../utils/helper';

// Custom hook
const useFileUpload = (filePath, type, maxFileSize, bucket = null) => {
  const [fileData, setFileData] = useState({
    fileName: '',
    uploaded: false,
    showProgress: false,
  });
  const [fileSizeError, setFileSizeError] = useState(false);

  const abortControllerRef = useRef(null);

  const { mutate, status, isError, error, data } = usePostUploadToS3(
    filePath,
    type,
    bucket,
  );

  const handleInputChange = (e, type) => {
    const fileUploaded = e.target.files[0];
    if (bytesToMegabytes(fileUploaded.size) > maxFileSize) {
      setFileSizeError(true);
      return;
    }
    const formData = new FormData();
    formData.append('file', fileUploaded);

    setFileData({
      fileName: fileUploaded.name,
      uploaded: false,
      showProgress: true,
    });

    abortControllerRef.current = new AbortController();

    mutate({ payload: formData, signal: abortControllerRef.current.signal });
  };

  useEffect(() => {
    if (status === 'success') {
      setFileData((prevFileData) => ({
        ...prevFileData,
        uploaded: true,
      }));
    }
  }, [status]);

  const resetFileData = () => {
    setFileData({
      fileName: '',
      uploaded: false,
      showProgress: false,
    });
  };

  const abortUpload = () => {
    if (abortControllerRef.current) {
      resetFileData();
      abortControllerRef.current.abort();
    }
  };

  return {
    fileData,
    setFileData,
    handleInputChange,
    abortUpload,
    isError,
    error,
    status,
    data,
    resetFileData,
    fileSizeError,
    setFileSizeError,
  };
};

export default useFileUpload;
