import ICONS from '../assets/icons';
import { PAGE_PERMISSIONS } from './permissions';

export const SIDEBAR_ITEMS = [
  {
    to: '/',
    exact: 'true',
    icon: ICONS.HOME,
    activeIcon: ICONS.HOME_ACTIVE,
    text: 'Home',
    alt: 'home',
  },
  {
    to: '/customers',
    icon: ICONS.USER_SIDE,
    activeIcon: ICONS.USER_SIDE_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_CUSTOMER,
    text: 'Customers',
    alt: 'customers',
  },
  {
    to: '/courses',
    icon: ICONS.COURSE,
    activeIcon: ICONS.COURSE_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_COURSE,
    text: 'Courses',
    alt: 'courses',
  },
  {
    to: '/orders',
    icon: ICONS.ORDERS,
    activeIcon: ICONS.ORDERS_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_ORDERS,
    text: 'Orders',
    alt: 'orders',
  },
  {
    // to: '/payments',
    icon: ICONS.PAYMENTS,
    activeIcon: ICONS.PAYMENTS_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_PAYMENTS,
    text: 'Payments',
    alt: 'payments',
    subItems: [
      {
        to: '/payments',
        icon: ICONS.INCOMING,
        activeIcon: ICONS.INCOMING_ACTIVE,
        permission: PAGE_PERMISSIONS.VIEW_PAYMENTS,
        text: 'Incoming',
        alt: 'Incoming',
      },
      {
        to: '/refund',
        icon: ICONS.REFUND,
        activeIcon: ICONS.REFUND_ACTIVE,
        permission: PAGE_PERMISSIONS.VIEW_PAYMENTS,
        text: 'Refund',
        alt: 'refund',
      },
    ],
  },
  {
    to: '/users',
    icon: ICONS.USERS_MANAGEMENT,
    activeIcon: ICONS.USERS_MANAGEMENT_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_ROLES_MANAGEMENT,
    text: 'User Management',
    alt: 'users',
  },
  {
    to: '/tests',
    icon: ICONS.COURSE,
    activeIcon: ICONS.COURSE_ACTIVE,
    permission: PAGE_PERMISSIONS.VIEW_TESTS,
    text: 'Tests',
    alt: 'tests',
  },
  {
    icon: ICONS?.EMPLOYERS,
    activeIcon: ICONS?.EMPLOYERS_ACTIVE,
    permissionsArray: [
      PAGE_PERMISSIONS?.VIEW_EMPLOYERS,
      PAGE_PERMISSIONS?.VIEW_PAYOUT_REQUESTS,
    ],
    text: 'Employers',
    alt: 'employers',
    subItems: [
      {
        to: '/employers',
        icon: ICONS.ACCOUNTS_SMALL,
        activeIcon: ICONS.ACCOUNTS_SMALL_ACTIVE,
        permission: PAGE_PERMISSIONS.VIEW_EMPLOYERS,
        text: 'Accounts',
        alt: 'accounts',
      },
      {
        to: '/payouts',
        icon: ICONS.PAYOUT_SMALL,
        activeIcon: ICONS.PAYOUT_SMALL_ACTIVE,
        permission: PAGE_PERMISSIONS.VIEW_PAYOUT_REQUESTS,
        text: 'Payout Requests',
        alt: 'payouts',
      },
    ],
  },
  {
    to: '/merchant-support',
    icon: ICONS?.EMPLOYERS,
    activeIcon: ICONS?.EMPLOYERS_ACTIVE,
    permission: PAGE_PERMISSIONS?.VIEW_FIELD_AGENT,
    text: 'Merchant Support',
    alt: 'merchant-support',
  },
];
