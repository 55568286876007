import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from './queryKeys';
import {
  handleGetAllCourses,
  handlePostAddCourse,
  handleGetCourseDetails,
  handlePostUploadCsv,
  handlePostAddAssessment,
  handleGetAllCustomers,
  handleGetCustomerDetails,
  handlePutCourseModule,
  handlePutCourseSubModule,
  handlePutCourseAssessment,
  handlePutCourse,
  handleDeleteCourseAssessment,
  handleDeleteCourse,
  handleGetAllPayments,
  handleGetAllOrders,
  handleGetPaymnetDetails,
  handleGetOrderDetails,
  handleGetCustomerOrder,
  handleGetCustomerPayment,
  handleGetCustomerCourses,
  handlePostIdentity,
  handlePostVerifyPassword,
  handlePostUploadToS3,
  handlePostEditPsychWeightage,
  handleGetGlobalData,
  handleGetRefundPayments,
  handleGetRefundDetails,
  handleGetUserDetails,
  handlePutResetPassword,
  handlePutUpdateTrueId,
  handlePostSendSms,
  handlePutUpdateCustomer,
  handlePostRemarks,
  handlePutCustomerDetails,
  handleGetReferrerDetails,
  handleGetReferralHistory,
  handleGetAllUsers,
  handleGetAllRoles,
  handleGetAllPermissions,
  handlePostUserRole,
  handlePutUserRole,
  handleDeleteUserRole,
  handlePostAddUser,
  handlePutUser,
  handleDeleteUser,
  handlePostUserPermission,
  handlePutUserPermission,
  handleDeleteUserPermission,
  handleGetTestDetails,
  handlePostUploadTestCsv,
  handlePostAddTest,
  handleGetAllTests,
  handlePutEditTest,
  handlePutDeleteTest,
  handlePutUpdateTestAssessment,
  handleGetTestCategories,
  handleEmpReferralDetails,
  handleEmpReferralEarnDetails,
  handleEmpCandidateReferralEarnDetails,
  handleGetAllPayouts,
  handlePostChangePayoutStatus,
  handleEmployerDetails,
  handleEmployerDocPostRemarks,
  handlePutUpdateEmployerStatus,
  handleGetAllEmployer,
  handleGetAllStaff,
  handlePostAddEmployer,
  handleGetEarnings,
  handlePostInitiateRefund,
  handlePutPanDetails,
  handleUploadAndDeleteAgreement,
  handleGetPayoutDetails,
  handlePutTrueIDDetails,
  handleGetAllFieldAgents,
} from './queryFunctions';

export const useGetAllCourses = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_COURSES,
      options?.itemsPerPage,
      options?.currentPage,
    ],
    queryFn: () => handleGetAllCourses(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetAllCustomers = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_CUSTOMERS,
      options?.currentPage,
      options?.itemsPerPage,
      options?.searchId,
      options?.filterKeys,
    ],
    queryFn: () => handleGetAllCustomers(options),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
export const usePostAddCourse = () => {
  return useMutation({
    mutationFn: (payload) => handlePostAddCourse(payload),
  });
};

export const useGetCourseDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COURSE_DETAILS],
    queryFn: () => handleGetCourseDetails(id),
    // enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePostUploadCsv = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePostUploadCsv(payload, id),
  });
};

export const usePutCourse = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutCourse(payload, id),
  });
};

export const usePutCourseModule = (id, options = {}) => {
  return useMutation({
    mutationFn: (payload) => handlePutCourseModule(payload, id),
  });
};

export const usePutCourseSubModule = (id, options = {}) => {
  return useMutation({
    mutationFn: (payload) => handlePutCourseSubModule(payload, id),
  });
};

export const usePostAddAssessment = () => {
  return useMutation({
    mutationFn: (payload) => handlePostAddAssessment(payload),
  });
};

export const usePutCourseAssessment = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutCourseAssessment(payload, id),
  });
};

export const useDeleteCourseAssessment = (id) => {
  return useMutation({
    mutationFn: () => handleDeleteCourseAssessment(id),
  });
};

export const useDeleteCourse = () => {
  return useMutation({
    mutationFn: (id) => handleDeleteCourse(id),
  });
};

export const useGetAllPayments = (options, queryOptions) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_PAYMENTS,
      options?.fromDate,
      options?.toDate,
      options?.searchId,
      options?.currentPage,
      options?.itemsPerPage,
      options?.filterKeys,
    ],
    queryFn: () => handleGetAllPayments(options),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: queryOptions?.enabled ?? true,
  });
};

export const useGetRefundPayments = (options, queryOptions) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_REFUND_PAYMENT,
      options?.fromDate,
      options?.toDate,
      options?.searchId,
      options?.currentPage,
      options?.itemsPerPage,
      options?.filterKeys,
    ],
    queryFn: () => handleGetRefundPayments(options),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: queryOptions?.enabled ?? true,
  });
};

export const useGetRefundPaymentDetails = (options) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_REFUND_PAYMENT_DETAILS],
    queryFn: () => handleGetRefundDetails(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetAllOrders = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_ORDERS,
      options?.currentPage,
      options?.itemsPerPage,
      options?.searchId,
      options?.fromDate,
      options?.toDate,
      options?.filterKeys,
    ],
    queryFn: () => handleGetAllOrders(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetPaymentDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_PAYMENTS_DETAILS],
    queryFn: () => handleGetPaymnetDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetOrderDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ORDER_DETAILS],
    queryFn: () => handleGetOrderDetails(id),
    // enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomerDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMER_DETAILS, id],
    queryFn: () => handleGetCustomerDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomerPayments = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMER_PAYMENTS],
    queryFn: () => handleGetCustomerPayment(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomerOrders = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMER_ORDERS],
    queryFn: () => handleGetCustomerOrder(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetCustomerCourses = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_CUSTOMER_COURSES],
    queryFn: () => handleGetCustomerCourses(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePostIdentity = () => {
  return useMutation({
    mutationFn: (payload) => handlePostIdentity(payload),
  });
};

export const usePostVerifyPassword = () => {
  return useMutation({
    mutationFn: ({ payload, token }) =>
      handlePostVerifyPassword(payload, token),
  });
};

export const usePostUploadToS3 = (filePath, type, bucket) => {
  return useMutation({
    mutationFn: ({ payload, signal }) =>
      handlePostUploadToS3(payload, signal, filePath, type, bucket),
  });
};

export const usePostEditPsychWeightage = () => {
  return useMutation({
    mutationFn: (payload) => handlePostEditPsychWeightage(payload),
  });
};

export const useGetGlobalData = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_GLOBAL_DATA],
    queryFn: () => handleGetGlobalData(),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetUserDetails = (userId, token) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_DETAILS, userId],
    queryFn: () => handleGetUserDetails(userId, token),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!userId && !!token,
  });
};

export const usePutResetPassword = (token) => {
  return useMutation({
    mutationFn: (payload) => handlePutResetPassword(payload, token),
  });
};

export const usePostRemarks = () => {
  return useMutation({
    mutationFn: (payload) => handlePostRemarks(payload),
  });
};

export const usePutUpdateTrueId = () => {
  return useMutation({
    mutationFn: (payload) => handlePutUpdateTrueId(payload),
  });
};

export const usePostSendSms = () => {
  return useMutation({
    mutationFn: (payload) => handlePostSendSms(payload),
  });
};

export const usePutUpdateCustomer = () => {
  return useMutation({
    mutationFn: (payload) => handlePutUpdateCustomer(payload),
  });
};

export const usePutCustomerDetails = () => {
  return useMutation({
    mutationFn: (payload) => handlePutCustomerDetails(payload),
  });
};

export const useGetReferrerDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_REFERRER_DETAILS, id],
    queryFn: () => handleGetReferrerDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetReferralHistory = (id, userType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_REFERRAL_ORDER_HISTORY, id],
    queryFn: () => handleGetReferralHistory(id, userType),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetTestDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_TEST_DETAILS, id],
    queryFn: () => handleGetTestDetails(id),
    // enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePostUploadTestCsv = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePostUploadTestCsv(payload, id),
  });
};

export const usePostAddTest = () => {
  return useMutation({
    mutationFn: (payload) => handlePostAddTest(payload),
  });
};

export const useGetAllTests = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_TESTS,
      options?.itemsPerPage,
      options?.currentPage,
    ],
    queryFn: () => handleGetAllTests(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePutEditTest = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutEditTest(payload, id),
  });
};

export const usePutDeleteTest = () => {
  return useMutation({
    mutationFn: (id) => handlePutDeleteTest(id),
  });
};

export const useGetAllUsers = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_USERS,
      options?.itemsPerPage,
      options?.currentPage,
    ],
    queryFn: () => handleGetAllUsers(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePutUpdateTestAssessment = () => {
  return useMutation({
    mutationFn: (payload) => handlePutUpdateTestAssessment(payload),
  });
};

export const useGetAllRoles = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_ROLES],
    queryFn: () => handleGetAllRoles(),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetAllPermissions = (options) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_PERMISSIONS],
    queryFn: () => handleGetAllPermissions(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const usePostUserRole = () => {
  return useMutation({
    mutationFn: (payload) => handlePostUserRole(payload),
  });
};

export const usePutUserRole = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutUserRole(payload, id),
  });
};

export const useDeleteUserRole = () => {
  return useMutation({
    mutationFn: (id) => handleDeleteUserRole(id),
  });
};

export const usePostAddUser = () => {
  return useMutation({
    mutationFn: (payload) => handlePostAddUser(payload),
  });
};

export const usePutUser = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutUser(payload, id),
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: (id) => handleDeleteUser(id),
  });
};

export const usePostUserPermission = () => {
  return useMutation({
    mutationFn: (payload) => handlePostUserPermission(payload),
  });
};

export const usePutUserPermission = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePutUserPermission(payload, id),
  });
};

export const useDeleteUserPermission = () => {
  return useMutation({
    mutationFn: (id) => handleDeleteUserPermission(id),
  });
};

export const useGetTestCategories = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_TEST_CATEGORIES],
    queryFn: () => handleGetTestCategories(),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useEmpReferralDetails = (id, options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_EMP_REFERRAL,
      id,
      options?.currentPage,
      options?.itemsPerPage,
      options?.activeSearchKey,
    ],
    queryFn: () => handleEmpReferralDetails(id, options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};
export const useEmpReferralEarnDetails = (id, type) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_EMP_REFERRAL, id, type],
    queryFn: () => handleEmpReferralEarnDetails(id, type),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useEmpCandidateReferralEarnDetails = (id, candidateId, type) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_EMP_REFERRAL, id, candidateId, type],
    queryFn: () => handleEmpCandidateReferralEarnDetails(id, candidateId, type),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!candidateId,
  });
};

export const useGetAllPayouts = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_PAYOUTS,
      options?.itemsPerPage,
      options?.currentPage,
      options?.filterKeys,
    ],
    queryFn: () => handleGetAllPayouts(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetPayoutDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_PAYOUT_DETAILS, id],
    queryFn: () => handleGetPayoutDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useGetEmployerDetails = (id) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EMPLOYER_DETAILS, id],
    queryFn: () => handleEmployerDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
    // enabled: !!id,
  });
};

export const useEmployerDocPostRemarks = () => {
  return useMutation({
    mutationFn: (payload) => handleEmployerDocPostRemarks(payload),
  });
};

export const usePutUpdateEmployerStatus = (employerId) => {
  return useMutation({
    mutationFn: (payload) => handlePutUpdateEmployerStatus(employerId, payload),
  });
};

export const useGetAllEmployer = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_EMPLOYER,
      options?.currentPage,
      options?.itemsPerPage,
    ],
    queryFn: () => handleGetAllEmployer(options),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    // enabled: false
  });
};

export const useGetAllStaff = (options) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_STAFF,
      options?.currentPage,
      options?.itemsPerPage,
      options?.searchId,
      options?.filterKeys,
      options?.staffingAgencyId,
    ],
    queryFn: () => handleGetAllStaff(options),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEarnings = (payload) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_EARNINGS],
    queryFn: () => handleGetEarnings(payload),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

export const usePostAddEmployer = () => {
  return useMutation({
    mutationFn: (payload) => handlePostAddEmployer(payload),
  });
};

export const usePostChangePayoutStatus = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePostChangePayoutStatus(payload, id),
  });
};

export const usePostInitiateRefund = (id) => {
  return useMutation({
    mutationFn: (payload) => handlePostInitiateRefund(payload, id),
  });
};

export const usePutPanDetails = () => {
  return useMutation({
    mutationFn: (payload) => handlePutPanDetails(payload),
  });
};

export const useUploadAndDeleteAgreement = (id) => {
  return useMutation({
    mutationFn: (payload) => handleUploadAndDeleteAgreement(payload, id),
  });
};

export const usePutTrueIDDetails = () => {
  return useMutation({
    mutationFn: (payload) => handlePutTrueIDDetails(payload),
  });
};

export const useGetAllFieldAgents = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_FIELD_AGENTS],
    queryFn: () => handleGetAllFieldAgents(),
    retry: false,
    refetchOnWindowFocus: false,
  });
};
